.logo {
    margin: 16px 24px 16px 0;
}

.site-layout-background {
    background: #fff;
}

.header {
    background-color: #09407A;
}

.nav-header {
    background-color: #09407A !important;
}